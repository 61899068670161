import * as React from "react";
import "../taskpane.css";
import LogoSvg from "../../assets/logo.svg";

export interface HeaderProps {
  title: string;
  logo: string;
  message: string;
}

export default class Header extends React.Component<HeaderProps> {
  render() {
    const { title, logo, message } = this.props;

    return (
      <section
        style={{
          padding: "20%",
          paddingBottom: 30,
          paddingTop: 100,
          flexDirection: "column",
          alignItems: "center",
          display: "flex",
          minWidth: 300,
        }}
      >
        <LogoSvg></LogoSvg>
        <h1
          style={{
            fontSize: 40,
            color: "#000",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            whiteSpace: "nowrap"
          }}
        >
          {message}
        </h1>
      </section>
    );
  }
}
