import * as React from "react";
import "../styles/login.css";
import { Documents1, FieldObject, RecieveMessage, WS } from "./WebSocketImpl";
import { useRef, useState } from "react";
import { Modal } from "antd";
import moment from "moment";
import CopySvg from "../../assets/copy.svg";
import TriangleSvg from "../../assets/triangle.svg";
import TriangleClient2Svg from "../../assets/triangleClient2.svg";
import TriangleClient3Svg from "../../assets/triangleClient3.svg";
import DeleteSelected from "../../assets/deleteSelectedDocument.svg";
import { IDocumentCategory, Document, IClientModel } from "./typing";
import { animated } from "@react-spring/web";
import CloseModal from "../../assets/closeModal.svg";
import clients from "../../clients";

export interface AuthProps {
  onButtonClick: (path: string) => void;
}

const Category: React.FC<AuthProps> = ({}) => {
  const customData: IDocumentCategory[] = require("../../data.json");
  const passport = customData.find((i) => i.title === "Удостоверения").listDocuments[0];

  const [document, setDocument] = useState(passport);
  const [activeTab, setActiveTab] = useState<Document>(passport);
  const [selectedRecieveDocuments, setSelectedRecieveDocuments] = useState([]);

  const [selectedDocuments, setSelectedDocuments] = useState<Map<number, Document[]>>(
    new Map([
      [1, [passport]],
      [2, [passport]],
      [3, [passport]],
    ])
  );
  const [localItems, setLocalItem] = React.useState<Map<number, Documents1[]>>(new Map());
  const [isRequestPage, setRequestPage] = useState<boolean>(false);
  const [client, setClient] = useState<IClientModel[]>(clients);
  const [activeClient, setActiveClient] = useState<IClientModel>(clients[0]);
  const [isRecivePage, setIsRecivePage] = React.useState(false);
  const testRef = useRef(null);

  // Модалки
  const [isAwaitingClient, setIsAwaitingClient] = React.useState(false);
  const [isModalFailed, setIsModalOpenFailed] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState("Нет активных клиентов");
  const [textModalFailed, setTextModalFailed] = React.useState("Ошибка");

  const getSvg = (id: number) => {
    const components = {
      1: TriangleSvg,
      2: TriangleClient2Svg,
      3: TriangleClient3Svg,
    };

    const SvgComponent = components[id];
    return <SvgComponent />;
  };

  // Обработчик события клика по вкладке клиента
  const onClickClient = (client: any) => {
    if (activeClient.id === client.id) {
      setRequestPage(!isRequestPage);
    } else {
      setRequestPage(false);
      setActiveClient(client);
    }
    setIsRecivePage(false);
  };

  // Обновление списков реквест и ресив документов для отправления клиенту
  const updateRequestOrReceiveSelectedDocuments = (document: string, checked: boolean, isRequest: boolean) => {
    if (isRequest && checked) {
      selectDocuments(document);
    } else if (isRequest && !checked) {
      unselectDocument(document);
    } else {
      const updatedSelectedDocuments = checked
        ? [...selectedRecieveDocuments, document]
        : selectedRecieveDocuments.filter((x) => x !== document);
      setSelectedRecieveDocuments(updatedSelectedDocuments);
    }
  };

  const selectDocuments = (doc) => {
    console.log("Selected Documents:", doc);

    const newSelectedDocuments = new Map(selectedDocuments);
    const dosc1 = newSelectedDocuments.get(activeClient.id) ?? [];

    if (!dosc1.find((y) => y.idServer == doc))
      dosc1.push(
        customData.map((c) => c.listDocuments.filter((r) => r.idServer == doc)).filter((z) => z.length != 0)[0][0]
      );

    if (dosc1.length !== 0) {
      newSelectedDocuments.set(activeClient.id, dosc1);
      setSelectedDocuments(newSelectedDocuments);
      setActiveTab(dosc1[0]);
      console.log("Updated Selected Documents:", dosc1);
    }
  };

  const onChangeValue = (idServerDocument: string, idServerField: string, value: any) => {
    console.log("adfafsd" + idServerField);
    setLocalItem((prevLocalItems) => {
      var updatedLocalItemsMap = new Map(prevLocalItems);
      var activeClietnMap = prevLocalItems.get(activeClient.id) ?? [];
      let document = activeClietnMap.find((x) => x.name === idServerDocument);
      if (document) {
        if (document.fields.find((x) => x.fieldName === idServerField)) {
          document.fields.map((x) => {
            if (x.fieldName === idServerField) {
              x.value = value;
            }
          });
        } else {
          document.fields.push({
            fieldName: idServerField,
            value: value == undefined ? "" : value,
            isGosUslugi: false,
          });
        }
      } else {
        var fields: FieldObject[] = [
          { fieldName: idServerField, value: value == undefined ? "" : value, isGosUslugi: false },
        ];
        activeClietnMap.push(new Documents1(idServerDocument, fields));
      }
      updatedLocalItemsMap.set(activeClient.id, activeClietnMap);
      return updatedLocalItemsMap;
    });
  };

  const podstavka = (documents: Documents1[]) => {
    documents.forEach((x) =>
      x.fields.forEach(async (fields) => {
        Word.run(function (ctx) {
          var results = ctx.document.body.search(" [" + x.name + "_" + fields.fieldName + "] ");
          ctx.load(results);
          return ctx
            .sync()
            .then(async function () {
              console.log(fields);
              for (var i = 0; i < results.items.length; i++) {
                ctx.load(results.items[i].font);
                await ctx.sync().then(function () {
                  if (results.items[i].font.highlightColor == activeClient.color) {
                    results.items[i].insertText(
                      localItems
                        .get(activeClient.id)
                        ?.find((t) => t.name === x.name)
                        .fields.find((x) => x.fieldName === fields.fieldName)?.value + " ",
                      "Replace"
                    );
                    results.items[i].font.highlightColor = null;
                  }
                });
              }
              return ctx.sync();
            })
            .then(ctx.sync);
        });
      })
    );
  };

  const insertParagraph = async (text: string) => {
    console.log(text);

    Word.run(async (context) => {
      const docBody = context.document.getSelection();
      docBody.load();
      await context.sync();
      console.log(docBody.text);
      docBody.insertText(" [" + text + "] ", Word.InsertLocation.end);
      docBody.select(Word.SelectionMode.end);
      docBody.font.highlightColor = activeClient.color;

      await context.sync();
    }).catch(function (error) {
      console.log(error.message);
    });
  };

  const unselectDocument = (doc) => {
    const newSelectedDocuments = new Map(selectedDocuments);
    const dosc1 = newSelectedDocuments.get(activeClient.id).filter((dcs) => dcs.idServer !== doc);
    if (dosc1.length !== 0) {
      newSelectedDocuments.set(activeClient.id, dosc1);
      setSelectedDocuments(newSelectedDocuments);
    }
  };

  const deleteClientDocument = () => {
    const newLocalItem = new Map(localItems);
    newLocalItem.delete(activeClient.id);
    setLocalItem(newLocalItem);

    console.log("activeClient =", activeClient.clientId);
    if (activeClient.clientId != null) {
      WS.getInstance().clientUnplug(activeClient.clientId);

      var freeClient = client.find((x) => x.clientId === activeClient.clientId);
      console.log("cle = ", freeClient);

      if (freeClient != null) {
        freeClient.clientId = null;
        setClient(Array.from(client));
        console.log("clientttt = ", client);
      }

      setActiveClient(client.find((x) => x.id === activeClient.id));
    }
  };

  const hideModal = () => {
    setIsModalOpenFailed(false);
    setModalMessage("Нет активных клиентов");
    setTextModalFailed("Ошибка");
  };

  WS.getInstance().addHandler((event) => {
    var resp = JSON.parse(event.data) as RecieveMessage;
    console.log("net11: Got %s message: %s", event.data);

    if (resp.messageType == "REQUEST_ANSWER") {
      let local: Documents1[] = [];
      console.log("resp.documents = ", resp.documents);

      // Преобразование JSON-строки в объект JavaScript
      const data = JSON.parse(JSON.stringify(resp.documents));

      // Обработка документов из сообщения
      for (const key in data) {
        console.log("key = ", key);
        if (Object.hasOwnProperty.call(data, key)) {
          const docArray = data[key];
          const docFields: FieldObject[] = [];

          if (Array.isArray(docArray)) {
            // Если документ представлен в виде массива
            for (const docData of docArray) {
              if (Array.isArray(docData)) {
                // Если документ представлен в виде вложенного массива
                const [fieldName, value] = docData;
                console.log("docData = ", docData);
                docFields.push(
                  new FieldObject(
                    fieldName,
                    fieldName.includes("date") && fieldName !== "auto_issue_date"
                      ? moment(value).format("DD.MM.yyyy")
                      : value,
                    false
                  )
                ); // Предполагаем, что isGosUslugi всегда false
              } else {
                // Если документ представлен в виде объекта
                console.log("docData 1= ", docData);

                const { fieldName, value, isGosUslugi } = docData;
                docFields.push(
                  new FieldObject(
                    fieldName,
                    fieldName?.includes("date") && fieldName !== "auto_issue_date"
                      ? moment(value).format("DD.MM.yyyy")
                      : value,
                    isGosUslugi
                  )
                );
              }
            }
          } else {
            // Если документ представлен в виде объекта
            const { fieldName, value, isGosUslugi } = docArray;
            docFields.push(
              new FieldObject(
                fieldName,
                fieldName?.includes("date") && fieldName !== "auto_issue_date"
                  ? moment(value).format("DD.MM.yyyy")
                  : value,
                isGosUslugi
              )
            );
          }
          local.push(new Documents1(key, docFields));
        }
      }

      // JSON.parse(resp.documents), (key, value) => {
      //   console.log('key = ', key);
      //   console.log("value = ", value);
      //   if (value instanceof Object && key != "") {
      //     // let localFields: Map<string, string> = new Map();
      //     let localFields: FieldObject[] = []

      //     JSON.parse(JSON.stringify(value), (key, value) => {
      //       console.log(key);
      //       if (key != "") {
      //         if (key.includes("date") && key !== "auto_issue_date") {
      //           // localFields.push(key, moment(value).format("DD.MM.yyyy"));
      //           localFields.push({ fieldName: key, value: moment(value).format("DD.MM.yyyy"), isGosUslugi: false });
      //         } else {
      //           // localFields.set(key, value);
      //           localFields.push({ fieldName: key, value: value, isGosUslugi: false });
      //         }
      //       }
      //     });

      //     local.push(new Documents1(key, localFields));
      //   }
      //   return value;
      // };

      var newLocalItems = new Map(localItems);

      if (newLocalItems.has(client.find((x) => x.clientId === resp.clientId).id)) {
        newLocalItems.get(client.find((x) => x.clientId === resp.clientId).id).map((x) => {
          if (local.find((y) => y.name === x.name) === null) local.push(x);
        });
      }
      newLocalItems.set(client.find((x) => x.clientId === resp.clientId).id, local);

      console.log("local = ", local);
      console.log("newLocal =", newLocalItems);
      setLocalItem(newLocalItems);
      setIsAwaitingClient(false);
      setRequestPage(false);
      setIsRecivePage(false);
      console.log(activeClient.id + document.idServer);
      // console.log(
      //   "Ddd" +
      //     localItems
      //       .get(activeClient.id)
      //       ?.find((t) => t.name === document.idServer)
      //       .fields.map((x) => {
      //         console.log(x);
      //       })
      // );
      // console.log(
      //   localItems
      //     .get(activeClient.id)
      //     ?.find((t) => t.name == document.idServer)
      //     .fields.find((y) => y.fieldName === "instagram")?.value ?? ""
      // );
    }

    if (resp.messageType == "OWN_CLIENT") {
      var freeClient = client.find((x) => x.clientId === resp.clientId) ?? client.find((x) => x.clientId === null);
      console.log("cle = ", freeClient);

      if (freeClient != null) {
        freeClient.clientId = resp.clientId;
        setActiveClient(freeClient);
        setRequestPage(false);
        setIsRecivePage(false);
        setClient(Array.from(client));
        console.log(
          "cle = ",
          client.find((x) => x.clientId == resp.clientId)
        );
        console.log("clientttt = ", client);
        setModalMessage("Подключен новый клиент: " + freeClient.title);
        setTextModalFailed("Оповещение");
        setIsModalOpenFailed(true);
      }
    }

    if (resp.messageType == "INFO") {
      setIsAwaitingClient(false);
      setIsModalOpenFailed(true);
      if (resp.code == 5) setModalMessage("Нет подключенных клиентов");
      else if (resp.code == 9)
        setModalMessage("Запрос был отправлен, для отправки нового запроса необходимо подождать");
      else if (resp.code == 17) setModalMessage("Недостаточно средств");
    }

    if (resp.messageType == "REFUSAL") {
      setIsAwaitingClient(false);
      setIsModalOpenFailed(true);
      setModalMessage("Отклоненно клиентом.");
    }

    if (resp.messageType == "DEPARTED_CLIENT") {
      var freeClient = client.find((x) => x.clientId === resp.clientId) ?? client.find((x) => x.clientId === null);
      console.log("cle = ", freeClient);

      setModalMessage(freeClient.title + " отключен");
      setTextModalFailed("Оповещение");
      setIsModalOpenFailed(true);
    }
  });

  // Тестовый блок field для отслеживания размерности высоты
  const testDiv = () => (
    <div style={{ visibility: "hidden" }} className="field">
      <div
        contentEditable="true"
        style={{ borderColor: "white", color: "white" }}
        className="input-field"
        ref={testRef}
        suppressContentEditableWarning={true}
        spellCheck="false"
      ></div>
      <button className="btn" style={{ backgroundColor: "transparent" }}>
        <CopySvg style={{ color: "white", visibility: "hidden" }}></CopySvg>
      </button>
    </div>
  );

  // Получение стилей text-area для увеличения и уменьшения высоты поля и определние падинга
  function textAreaAdjust(fieldIdServer: string) {
    const value = localItems
      .get(activeClient.id)
      ?.find((t) => t.name === document.idServer)
      ?.fields.find((x) => x.fieldName === fieldIdServer)?.value;

    if (testRef.current && value) {
      testRef.current.textContent = value;
      const scrollHeight = testRef.current.scrollHeight;
      testRef.current.textContent = "";

      const height = scrollHeight < 51 ? 25 : scrollHeight - 18;
      const paddingTop = scrollHeight < 51 ? 20 : 0;

      return { height, paddingTop };
    }

    return {};
  }

  function getStyleGosUslugi(fieldIdServer: string) {
    const value = localItems
      .get(activeClient.id)
      ?.find((t) => t.name === document.idServer)
      ?.fields.find((x) => x.fieldName === fieldIdServer)?.isGosUslugi;

    return value
      ? {
          borderStyle: "solid",
          borderWidth: "2px",
          borderImage: "linear-gradient(to bottom, #1B74C6, #F11212) 1",
        }
      : {};
  }

  // Получение массива с выбранными документами для отображения в карточке отправить
  const receiveData = (): IDocumentCategory[] => {
    const clientDocs = localItems.get(activeClient.id) || [];

    const filteredData = Array.from(customData).filter((data) => {
      return data.listDocuments.some((doc) => clientDocs.some((clientDoc) => clientDoc.name === doc.idServer));
    });

    return filteredData;
  };

  const logout = () => {
    localStorage.setItem("pluginId", "");
    localStorage.setItem("pluginSecret", "");
    localStorage.setItem("expirationDate", "");
    WS.getInstance().webSocket?.close();
  };

  return (
    <div className="container">
      <div className="select-container">
        <div style={{ display: "flex", gap: 5 }}>
          {client.map((x) => {
            return (
              <div
                style={activeClient.id === x.id ? x.boxShadow : {}}
                className={x.style}
                onClick={() => {
                  onClickClient(x);
                  setActiveTab(selectedDocuments.get(x.id)[0]);
                  setDocument(selectedDocuments.get(x.id)[0]);
                }}
              >
                <p className="btnTitle">{x.title}</p>
                <animated.div
                  style={
                    activeClient.id === x.id && isRequestPage
                      ? { transform: "rotate(180deg)", transition: "transform 0.3s ease-out" }
                      : { transform: "rotate(0deg)", transition: "transform 0.3s ease-out" }
                  }
                >
                  <div style={{ display: "flex", paddingTop: 4 }}>{getSvg(x.id)}</div>
                </animated.div>
              </div>
            );
          })}
        </div>
        <button className="logout-btn" onClick={() => logout()}>
          ВЫЙТИ
        </button>
      </div>
      <div className="tabConteiner">
        {selectedDocuments.get(activeClient.id).map((d) => {
          return (
            <div
              className={`tab tabClient` + activeClient.id}
              style={
                activeTab === d
                  ? { border: `1px solid ${activeClient.colorTab}`, color: `${activeClient.colorTab}` }
                  : { color: `${activeClient.colorTab}` }
              }
            >
              <div
                className="tab-text"
                onClick={() => {
                  var it = customData
                    .map((x) => {
                      return x.listDocuments.filter((y) => y.id === d.id);
                    })
                    .filter((z) => z.length != 0)[0][0];
                  setDocument(it);
                  setActiveTab(it);
                  setRequestPage(false);
                  setIsRecivePage(false);
                  setSelectedRecieveDocuments([]);
                }}
              >
                {d.title}
              </div>
              <div
                style={{ padding: 10, paddingBottom: 8, cursor: "pointer" }}
                onClick={() => {
                  unselectDocument(d.idServer);
                }}
              >
                <DeleteSelected></DeleteSelected>
              </div>
            </div>
          );
        })}
      </div>
      {!isRequestPage ? (
        <div style={{ opacity: 1, animation: "fadeIn ease-in .5s backwards" }} className="fields-container">
          {document.fields.map((x) => (
            <div className="field" key={x.idServer}>
              <textarea
                style={{ ...textAreaAdjust(x.idServer), ...getStyleGosUslugi(x.idServer) }}
                className="input-field"
                title={
                  localItems
                    .get(activeClient.id)
                    ?.find((t) => t.name == document.idServer)
                    ?.fields.find((y) => y.fieldName === x.idServer)?.isGosUslugi
                    ? "Взято из госуслуг"
                    : ""
                }
                id={x.idServer}
                disabled={
                  localItems
                    .get(activeClient.id)
                    ?.find((t) => t.name == document.idServer)
                    ?.fields.find((y) => y.fieldName === x.idServer)?.isGosUslugi
                }
                onChange={(val) => onChangeValue(document.idServer, x.idServer, val.target.value)}
                required
                value={
                  localItems.get(activeClient.id)?.find((t) => t.name === document.idServer)
                    ? localItems
                        .get(activeClient.id)
                        ?.find((t) => t.name == document.idServer)
                        .fields.find((y) => y.fieldName === x.idServer)?.value ?? ""
                    : ""
                }
              />
              <label className="labelInput" htmlFor={x.idServer}>
                {x.title}
              </label>
              <button className="btn" onClick={() => insertParagraph(document.idServer + "_" + x.idServer)}>
                <CopySvg></CopySvg>
              </button>
            </div>
          ))}
          {testDiv()}
        </div>
      ) : !isRecivePage ? (
        <div style={{ paddingBottom: 100, opacity: 1, animation: "fadeIn2 ease-in .5s backwards" }}>
          <p className="requestRecivePageTitle">Выберите документы, которые хотите запросить.</p>
          <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "0.5rem", marginRight: 30 }}>
            {customData.map((x) => (
              <div>
                <p className="requestRecivePageCategory">{x.title}</p>
                {x.listDocuments.map((y) => (
                  <div style={{ textAlign: "right" }}>
                    <label className="checkbox-button requestRecivePageLabel" htmlFor={y.title + x.title}>
                      <span>{y.title}</span>
                      <input
                        className="checkbox-button__input"
                        style={{ marginLeft: 15 }}
                        defaultChecked={
                          selectedDocuments.get(activeClient.id).find((f) => f.idServer == y.idServer) ? true : false
                        } // {selectedDocuments.get(activeClient.id).find(y.idServer)}
                        onClick={(f) => {
                          const target = f.target as HTMLInputElement;
                          updateRequestOrReceiveSelectedDocuments(y.idServer, target.checked, true);
                          console.log(y.idServer, target.checked);
                        }}
                        type="checkbox"
                        id={y.title + x.title}
                        name={y.title}
                      />
                      <span className={activeClient.styleCheckbox}></span>
                    </label>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div style={{ paddingBottom: 100, opacity: 1, animation: "fadeIn2 ease-in .5s backwards" }}>
          {receiveData().length === 0 ? (
            <p className="requestRecivePageTitle">Вы не изменили ни одного документа</p>
          ) : (
            <>
              <p className="requestRecivePageTitle">Выберите документы, которые хотите отправить.</p>
              <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "0.5rem", marginRight: 30 }}>
                {receiveData().map((x) => (
                  <div>
                    <p className="requestRecivePageCategory">{x.title}</p>
                    {x.listDocuments.map((y) =>
                      localItems.get(activeClient.id).find((t) => t.name === y.idServer) ? (
                        <div key={y.title + "receive"} style={{ textAlign: "right" }}>
                          <label
                            className="checkbox-button requestRecivePageLabel"
                            htmlFor={"recieve" + y.title + x.title}
                          >
                            <span>{y.title}</span>
                            <input
                              className="checkbox-button__input"
                              style={{ marginLeft: 15 }}
                              onClick={(f) => {
                                const target = f.target as HTMLInputElement;
                                updateRequestOrReceiveSelectedDocuments(y.idServer, target.checked, false);
                                console.log(y.idServer, target.checked);
                              }}
                              type="checkbox"
                              id={"recieve" + y.title + x.title}
                              name={y.title + "receive"}
                            />
                            <span className="checkbox-button__control"></span>
                          </label>
                        </div>
                      ) : null
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      )}

      <div className="button-container">
        <div style={{ display: "flex" }}>
          <button
            className="request-btn"
            onClick={() => {
              setSelectedRecieveDocuments([]);

              if (!isRecivePage) {
                if (activeClient.clientId === null) {
                  setIsModalOpenFailed(true);
                } else {
                  console.log("activeClientId = ", activeClient);
                  var requestDocuments = selectedDocuments.get(activeClient.id).map((x) => {
                    return x.idServer;
                  });
                  WS.getInstance().requestAnswerMessage(requestDocuments, activeClient.clientId);
                  setIsAwaitingClient(true);
                }
              }
            }}
          >
            ЗАПРОСИТЬ
          </button>

          <button className="request-btn" onClick={() => podstavka(localItems.get(activeClient.id))}>
            ВСТАВИТЬ
          </button>
          <button
            className="request-btn"
            onClick={() => {
              if (!isRequestPage) {
                setRequestPage(true);
                setIsRecivePage(true);
                setSelectedRecieveDocuments([]);
              } else if (
                isRequestPage &&
                isRecivePage &&
                receiveData().length != 0 &&
                selectedRecieveDocuments.length != 0
              ) {
                if (activeClient.clientId === null) {
                  setIsModalOpenFailed(true);
                } else {
                  console.log("activeClientId = ", activeClient);
                  WS.getInstance().recieveAnswerMessage(
                    localItems.get(activeClient.id),
                    selectedRecieveDocuments,
                    activeClient.clientId
                  );
                  setSelectedRecieveDocuments([]);
                }
              } else {
                setIsRecivePage(true);
                setSelectedRecieveDocuments([]);
              }
            }}
          >
            ОТПРАВИТЬ
          </button>
        </div>
        <button
          style={{ marginRight: 35, color: "#F01313", maxWidth: 52 }}
          className="request-btn"
          onClick={() => deleteClientDocument()}
        >
          DEL
        </button>
        <Modal
          open={isModalFailed}
          onCancel={hideModal}
          centered
          title={
            <p style={{ textAlign: "center", fontSize: 24, fontWeight: 400, marginBottom: 0 }}>{textModalFailed}</p>
          }
          bodyStyle={{ textAlign: "center", fontSize: 20, fontWeight: 400, marginTop: 0, paddingBottom: 20 }}
          maskStyle={{ backgroundColor: "transparent" }}
          style={{
            border: "2px solid #00B2F4",
            borderRadius: 10,
            textAlign: "center",
            color: "#000",
            fontFamily: "Inter",
          }}
          width={"70%"}
          closeIcon={<CloseModal style={{ margin: -10 }}></CloseModal>}
          footer={[]}
        >
          <p style={{ marginTop: 0 }}>{modalMessage}</p>
        </Modal>

        <Modal
          closeIcon={<CloseModal style={{ margin: -10 }}></CloseModal>}
          centered
          footer={[]}
          title={[]}
          bodyStyle={{ textAlign: "center", fontSize: 20, fontWeight: 400, paddingTop: 30, paddingBottom: 30 }}
          maskStyle={{ backgroundColor: "transparent" }}
          style={{
            border: "2px solid #00B2F4",
            borderRadius: 10,
            textAlign: "center",
            color: "#000",
            fontFamily: "Inter",
          }}
          width={"70%"}
          open={isAwaitingClient}
          onCancel={() => {
            setIsAwaitingClient(false);
          }}
          onOk={() => {}}
        >
          <p>Ожидаем разрешение клиента</p>
        </Modal>
      </div>
    </div>
  );
};

export default Category;
