import { IClientModel } from "./taskpane/components/typing";

const clients: IClientModel[] =[
    {
        title: "Клиент 1",
        id: 1,
        clientId: null,
        style: "btnClient",
        styleCheckbox: "checkbox-button__control",
        boxShadow: {
          boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.70)",
          borderWidth: "2px"
        },
        color: "#C0C0C0",
        colorTab: "#000000",
      },
      {
        title: "Клиент 2",
        id: 2,
        clientId: null,
        style: "btnClient btnClientTwo",
        styleCheckbox: "checkbox-button__control2",
        boxShadow: {
          boxShadow: "2px 2px 4px 0px rgba(0, 177, 18, 0.70)",
          borderWidth: "2px"
        },
        color: "#00FF00",
        colorTab: "#00B112",
      },
      {
        title: "Клиент 3",
        id: 3,
        clientId: null,
        style: "btnClient btnClientThree",
        styleCheckbox: "checkbox-button__control3",
        boxShadow: {
          boxShadow: "2px 2px 4px 0px rgba(21, 127, 203, 0.70)",
          borderWidth: "2px"
        },
        color: "#00FFFF",
        colorTab: "#157FCB",
      },
]

export default clients;