import moment from "moment";

export class WS {
    public webSocket?: WebSocket;
    private static ws?: WS;
    public connected = false;
    public isOpen = false;
    private plugin_id?: string;
    private plugin_secret?: string;
    private recognized: boolean = false;
    private handlers?: ((e: MessageEvent) => void);
    private handlersClose?: ((e: Event) => void);
    private messageQueue: string = "";


    public client: string;

    private constructor() {
    }

    static getInstance(): WS {
        // if (!this.ws?.connected || this.ws == null || this.ws.webSocket == undefined || this.ws.webSocket?.readyState == 3) {
        if (this.ws == null) {
            console.log("ws is null, create new WS");
            this.ws = new WS();
        }
        return this.ws;
    }

    public createWebSocket(): WebSocket {
        const ws = new WebSocket("wss://api.time-money.shop/api/documents/con");
        console.log("new websocket = ", ws);

        return ws;
    }

    public addHandler(handler: (e: MessageEvent) => void) {
        this.handlers = handler;
    }

    public addHandlerClose(handler: (e: CloseEvent) => void) {
        this.handlersClose = handler;
    }

    private flushMessageQueue() {
        if (this.messageQueue.length > 0 && this.webSocket?.readyState === 1) {
            const message = this.messageQueue;
            if (message) {
                this.webSocket.send(message);
            }
        }
    }

    public sendMessage(message: string) {
        console.log("send message = ", message);
        if (this.webSocket && this.webSocket.readyState === 1) {
            this.webSocket.send(message);
        } else {
            console.warn("WebSocket not ready, save message");
            this.messageQueue = message;
        }
    }

    public connect(returnAuth: (path: string) => void) {
        var finalWs: WebSocket;
        if (this.webSocket == undefined || this.webSocket.readyState !== 1)
            finalWs = this.createWebSocket();
        else {
            finalWs = WS.getInstance().webSocket!;
        }

        finalWs.onopen = () => {
            console.log('WS opened');
            this.flushMessageQueue();
            this.isOpen = true;
        }

        finalWs.onclose = (e) => {
            console.log('WS closed, ex = ', e);
            returnAuth("/");
            this.connected = false;
        };

        finalWs.onerror = (e) => {
            console.log('WS error = ', e);
            this.connected = false;
        };

        finalWs.onmessage = (e: MessageEvent) => {
            const msg = JSON.parse(e.data) as RequestMessage;
            console.log("net: Got %s message in Websocket", e.data);
            if (msg.messageType == "HELLO_ANSWER") {
                this.connected = true;
                WS.getInstance().setClient(msg.clientId)

                var pluginIds = localStorage.getItem("plugins");

                var plugins: { id: string; secret: string, date: Date }[] = JSON.parse(pluginIds!) ? JSON.parse(pluginIds!) : [];

                console.log("plugins= ", plugins);
                if (plugins.find((p) => p.id == this.plugin_id) == undefined) {
                    if (plugins.length >= 5) {
                        plugins.sort((a, b) => b.date.getTime() - a.date.getTime());
                    }
                    else {
                        plugins.push({ id: this.plugin_id, secret: this.plugin_secret, date: new Date() });
                    }
                    localStorage.setItem("plugins", JSON.stringify(plugins));
                }
                localStorage.setItem("pluginId", this.plugin_id);
                localStorage.setItem("pluginSecret", this.plugin_secret);
                localStorage.setItem("expirationDate", moment(moment.now()).add(10, "hour").format());
                returnAuth("/category");

            }
            if (msg.messageType == "OWN_CLIENT") {

                WS.getInstance().setClient(msg.clientId)
            }
            const instance = WS.getInstance();
            instance.handlers!(e);
        }

        this.webSocket = finalWs;
    }

    public close() {
        if (this.connected) {
            this.webSocket?.close();
            this.connected = false;
            this.isOpen = false;
        }
    }

    public isConnected(): boolean {
        return this.connected;
    }

    public isRecognized(): boolean {
        return this.recognized;
    }

    public setClient(client: string): void {
        this.client = client;
    }

    public requestAnswerMessage = (idServer: any, clientId: string) => {
        console.log("send request message")
        const msg = {
            messageType: "REQUEST",
            userType: "PLUGIN",
            clientId: clientId,
            documents: idServer,
        };

        WS.getInstance().sendMessage(JSON.stringify(msg));
    };

    public clientInfoMessage = () => {
        console.log("send client info")
        const msg = {
            messageType: "CLIENT_INFO",
        };
        WS.getInstance().sendMessage(JSON.stringify(msg));
    };

    public clientUnplug = (clientId: string) => {
        console.log("send unplug client")
        const msg = {
            messageType: "UNPLUG",
            userType: "PLUGIN",
            clientId: clientId,
        };
        WS.getInstance().sendMessage(JSON.stringify(msg));
    };

    public helloMessage = (plugin_id: string, plugin_secret: string) => {
        this.plugin_id = plugin_id;
        this.plugin_secret = plugin_secret;
        console.log("plugin_id = ", plugin_id, "plugin_secret = ", this.plugin_id);
        console.log("send hello message")
        const msg = {
            messageType: "HELLO_PLUGIN",
            userType: "PLUGIN",
            id: plugin_id,
            secret: plugin_secret
        };
        WS.getInstance().sendMessage(JSON.stringify(msg));
    }

    public recieveAnswerMessage = (localItems: Documents1[], idServer: string[], clientId: string) => {
        let documents = new Map<string, any>();

        idServer.forEach(id => {
            const mp = localItems.find((x) => x.name === id).fields;
            documents.set(id, mp);
        })

        const msg = {
            messageType: "RECEIVE",
            userType: "PLUGIN",
            documents: Object.fromEntries(documents),
            clientId: clientId,
        };

        WS.getInstance().sendMessage(JSON.stringify(msg));
    };

    public getIsOpen(): boolean {
        return this.isOpen;
    }
}

export default class RequestMessage {
    messageType?: string;
    // documents?: string[];
    // req_id?: string;
    // plugin_credentials?: string;
    clientId: string;

    constructor() {
        this.clientId = "request";
        this.messageType = "dsafasdf";
    }
}

export class RequestAnswerMessage {
    message_type: string;
    documents: { [k: string]: any };
    req_id?: string;

    constructor(
        message_type: string,
        documents: { [k: string]: any },
        req_id: string
    ) {
        this.message_type = message_type;
        this.documents = documents;
        this.req_id = req_id;
    }
}

export class RecieveMessage {
    messageType?: string;
    documents: Documents[];
    reqId?: string;
    text?: string;
    clientId: string;
    code: number;

    constructor(documents: Documents[]) {
        this.messageType = "recieve";
        this.documents = documents;
        this.clientId = "dsafasdf";
    }
}

export class Documents {
    name: string;
    fields: FieldObject[];

    constructor(
        name: string,
        fields: FieldObject[]
    ) {
        this.name = name;
        this.fields = fields;
    }
}

export class FieldObject {
    fieldName: string;
    value: string;
    isGosUslugi: boolean;
    constructor(
        fieldName: string,
        value: string,
        isGosUslugi: boolean
    ) {
        this.fieldName = fieldName;
        this.value = value;
        this.isGosUslugi = isGosUslugi;
    }
  };

export class Documents1 {
    name: string;
    fields: FieldObject[];

    constructor(
        name: string,
        fields: FieldObject[]
    ) {
        this.name = name;
        this.fields = fields;
    }
}

export class Fields {
    name: string;
    value: string;

    constructor(
        name: string,
        value: string
    ) {
        this.name = name;
        this.value = value;
    }
}