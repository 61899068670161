import * as React from "react";
import "../styles/login.css";
import "../styles/general.css";
import "../styles/One.css";
import Header from "./Header";
import { RecieveMessage, WS } from "./WebSocketImpl";
import { Modal } from "antd";
import CloseModal from "../../assets/closeModal.svg";
import moment from "moment";

export interface AuthProps {
  onButtonClick: (path: string) => void;
}

const Auth: React.FC<AuthProps> = ({ onButtonClick }) => {
  const [pluginId, setPluginId] = React.useState("");
  const [pluginSecret, setPluginSecret] = React.useState("");

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState("Не удалось подключиться");

  const [localPlugins, setlocalPlugins] = React.useState([]);

  React.useEffect(() => {
    // WS.getInstance().connect(onButtonClick);
    setlocalPlugins(JSON.parse(localStorage.getItem("plugins")));
    console.log("all plugins = ", localPlugins);
    var storagePluginId = localStorage.getItem("pluginId");
    var storagePluginSecret = localStorage.getItem("pluginSecret");
    var storageExpiredDate = localStorage.getItem("expirationDate");

    console.log(storagePluginId, storagePluginSecret, storageExpiredDate);
    console.log(moment(moment.now()).isAfter(moment(storageExpiredDate)));
    console.log("ws connected = ", WS.getInstance().getIsOpen());

    if (
      moment(moment.now()).isBefore(moment(storageExpiredDate)) &&
      storagePluginId != null &&
      storagePluginSecret != null
    ) {
      console.log("hello message");
      WS.getInstance().connect(onButtonClick);
      WS.getInstance().helloMessage(storagePluginId, storagePluginSecret);
    }
  }, []);


  WS.getInstance().addHandler((data) => {
    const msg = JSON.parse(data.data) as RecieveMessage;

    console.log("net: Got %s message in auth: %s", msg.messageType);

    if (msg.messageType == "HELLO_ANSWER") {
    } else {
      setIsModalOpen(true);
      if (msg.code == 2) setModalMessage("Данный плагин уже подключен к сети.");
      else if (msg.code == 13) setModalMessage("Вы ввели неверный ID или Secret code попробуйте еще раз!");
      else if (msg.code == 15)
        setModalMessage(
          "Истек срок действия вашего плагина! В личном кабинете вы можете продлить его на следующий период."
        );
      else setModalMessage("Не удалось подключиться.");
    }
  });

  const hideModal = () => {
    setIsModalOpen(false);
    setModalMessage("Не удалось подключиться");
  };

  const connect = () => {
    if (pluginId == "" || pluginSecret == "") {
      setModalMessage("Оба поля обязательны.");
      setIsModalOpen(true);
    } else {
      if (WS.getInstance().isConnected() == true) {
        console.log("ws is connected");
        onButtonClick("/category");
      } else {
        if (WS.getInstance().webSocket == undefined || WS.getInstance().webSocket.readyState !== 1) {
          console.log("connected in ws undefined or not connected");
          WS.getInstance().connect(onButtonClick);
          WS.getInstance().helloMessage(pluginId, pluginSecret);
        } else {
          console.log("connected in ws connected");
          WS.getInstance().helloMessage(pluginId, pluginSecret);
        }
      }
    }
  };

  return (
    <div>
      <Modal
        open={isModalOpen}
        onCancel={hideModal}
        title={<p style={{ textAlign: "center", fontSize: 20, fontWeight: 400, marginTop: 0 }}>ВНИМАНИЕ!</p>}
        bodyStyle={{ textAlign: "center", fontSize: 16, fontWeight: 400 }}
        maskStyle={{ backdropFilter: "blur(6px)", backgroundColor: "transparent", fontSize: 36, fontWeight: 400 }}
        style={{
          border: "2px solid #00B2F4",
          borderRadius: 10,
          textAlign: "center",
          color: "#000",
          fontFamily: "Inter",
        }}
        width={"50%"}
        closeIcon={<CloseModal style={{ margin: -10 }}></CloseModal>}
        centered
        footer={[]}
      >
        <p>{modalMessage}</p>
      </Modal>
      <Header logo={require("./../../../assets/logo-filed.png")} title={""} message="Добро пожаловать!" />
      <div>
        <div style={{ padding: "0px 15%" }}>
          <div
            style={{
              padding: "0.7rem 1.5rem 35px",
              borderRadius: "0.5rem",
              background: "#fff",
              border: "1px solid #eee",
              boxShadow: "4px 4px 13px 4px rgba(0, 0, 0, 0.25)",
              minWidth: 300,
            }}
          >
            <h3
              style={{
                fontSize: 30,
                textAlign: "center",
                textTransform: "uppercase",
                fontWeight: 500,
                color: "#000",
                fontFamily: "Inter",
                margin: 0,
              }}
            >
              Вход
            </h3>
            <div>
              <div style={{ paddingBottom: 15, paddingTop: 15 }} className="field">
                <input
                  id="pluginId"
                  type="text"
                  className="input-field-auth"
                  onChange={(w) => {
                    setPluginId(w.target.value);
                    setPluginSecret(localPlugins.find((p) => p.id == w.target.value).secret);
                  }}
                  required
                  list="idPlugins"
                />
                <label htmlFor="pluginId" className="labelInput">
                  Plugin ID:
                </label>
                {localPlugins != null ? (
                  <datalist id="idPlugins">
                    {localPlugins
                      .sort((a, b) => moment(b.date).milliseconds() - moment(a.date).milliseconds())
                      .map((plugin) => (
                        <option value={plugin.id}></option>
                      ))}
                  </datalist>
                ) : null}
              </div>
              <div style={{ paddingBottom: 15 }} className="field">
                <input
                  type="text"
                  maxLength={60}
                  value={pluginSecret}
                  className="input-field-auth"
                  id="pluginSecret"
                  onChange={(w) => setPluginSecret(w.target.value)}
                  required
                />
                <label htmlFor="pluginSecret" className="labelInput">
                  Secret code:
                </label>
              </div>
            </div>
          </div>
          <div style={{ textAlign: "center", padding: "0 2rem", marginTop: "-25px", minWidth: 300 }}>
            <button
              style={{
                padding: "13px",
                fontSize: "20px",
                border: "none",
                borderRadius: "35px",
                background: "#00B2F4",
                width: "70%",
                color: "white",
                textTransform: "uppercase",
                fontFamily: "Inter",
              }}
              onClick={connect}
            >
              Подключение
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
