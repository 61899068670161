import * as React from "react";
import Progress from "./Progress";
import "../taskpane.css";
import Auth from "./Auth";
import Category from "./Category";
import { Component, Children, ReactElement } from "react";

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export interface AppState {
  listItems: object[];
}

interface FragmentProps {
  path: string;
  content: ReactElement<any, any>;
}

class Fragment extends Component<FragmentProps> {
  constructor(props) {
    super(props);
  }

  render() {
    return <div style={{ width: "100%", height: "100%" }}>{this.props.content}</div>;
  }

  public getPath() {
    return this.props.path;
  }
}

interface PageState {
  currentPath: string;
  navigationStack: string[];
}

class Page extends Component<any, PageState> {
  items: Map<string, Component> = new Map();

  constructor(props) {
    super(props);
    this.state = {
      currentPath: "/",
      navigationStack: [],
    };
    this.childFactory();
  }

  private childFactory = () => {
    Children.forEach(this.props.children, (child) => {
      const props = child["props"];
      const path = props["path"];
      const content = props["content"];
      if (path && content) {
        const c = child as Fragment;
        if (!this.items.has(path)) {
          this.items.set(path, c);
        }
      }
    });
  };

  private getCurentFragment(path: string) {
    return this.items.get(path);
  }

  public navigateToFragment(path: string) {
    if (this.state.currentPath === path) {
      return;
    }
    const navStack = Array.from(this.state.navigationStack);
    navStack.push(path);
    const state: PageState = {
      currentPath: path,
      navigationStack: navStack,
    };
    this.setState(state);
  }

  render() {
    return <div style={{ width: "100%", height: "80%" }}>{this.getCurentFragment(this.state.currentPath)}</div>;
  }
}

export default class App extends React.Component<AppProps, AppState> {
  page = React.createRef<Page>();

  constructor(props, context) {
    super(props, context);
    this.state = {
      listItems: [],
    };
  }

  render() {
    const { title, isOfficeInitialized } = this.props;

    if (!isOfficeInitialized) {
      return (
        <Progress
          title={title}
          logo={require("./../../../assets/logo-filed.png")}
          message="Please sideload your addin to see app body."
        />
      );
    }

    return (
      <div>
        <Page ref={this.page}>
          <Fragment
            path={"/"}
            content={
              <Auth
                onButtonClick={(path: string) => {
                  if (this.page.current) {
                    const p = this.page.current as Page;
                    p.navigateToFragment(path);
                  }
                }}
              />
            }
          />
          <Fragment
            path={"/category"}
            content={
              <Category
                onButtonClick={(path: string) => {
                  if (this.page.current) {
                    const p = this.page.current as Page;
                    p.navigateToFragment(path);
                  }
                }}
              />
            }
          />
        </Page>
      </div>
    );
  }
}
